import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./othercluster.scss";
import clusterimages from "./othercluster.js";

const cluster = () => {
  const settings1 = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    lazyload: true,
  };
  return (
    <div className="cluster1">
      <div className="devider-cluster">
        <div className="judul-cluster">Commersial's in Asya Jakarta</div>
        <div className="carousel-logocluster">
          <Slider {...settings1}>
            {clusterimages.map((image, index) => (
              <img
                className="kartugambar12"
                key={index}
                src={image}
                alt="logo"
              />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default cluster;
