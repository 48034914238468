import React from "react";
import "./Produk1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Kelimutuimages from "./Kelimutu.js";
import Matanaimages from "./Matana.js";
import Sentarumimages from "./Sentarum.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Dayanawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281287000949&text=Halo%20Antonius,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Asya%20Jakarta%20(Matana)%20https://marketing-asyajakarta.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Amandawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281287000949&text=Halo%20Antonius,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Asya%20Jakarta%20(Kelimutu)%20https://marketing-asyajakarta.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Cynthiawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6281287000949&text=Halo%20Antonius,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Asya%20Jakarta%20(Sentarum)%20https://marketing-asyajakarta.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard3">
        <div className="card">
          <Slider {...settings}>
            {Matanaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Matana</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="Grey" icon={faMedal} />
              <div className="namalokasi">Discount up To 15%</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">15</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 98m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 115m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Dayanawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Kelimutuimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Kelimutu</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="Grey" icon={faMedal} />
              <div className="namalokasi">Discount up to 12%</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">17</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 90m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 119m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 2</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Amandawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Sentarumimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Sentarum</div>
            <div className="gridmap">
              <FontAwesomeIcon className="icon" color="Grey" icon={faMedal} />
              <div className="namalokasi">Discount Up to 20%</div>
            </div>
          </div>
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">17</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <div className="gridspek">
            <FontAwesomeIcon color="#2b2b2b" icon={faArrowsUpDownLeftRight} />
            <span> 105m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faHouse} />
            <span> 121m²</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="#2b2b2b" icon={faShower} />
            <span> 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Cynthiawa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
