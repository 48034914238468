import React from "react";
import "./footer.scss";
import logo from "../../Media/Asya.png";

const footer = () => {
  return (
    <div className="container-footer">
      <div className="container-atas">
        <div className="container-logo">
          <img className="gambar-logo" src={logo} alt="logo-Parkspring" />
        </div>
        <div className="container-deskripsi">
          <div className="alamat">
            <h1>Marketing Gallery</h1>
            <h2>Asya Jakarta</h2>
            <h3>
              Jl. Jkt Garden City Boulevard Jl. Asya Boulevard, Cakung Tim.,
              Kec. Cakung, Kota Jakarta Timur, Daerah Khusus Ibukota Jakarta
              13910
            </h3>
            <h4>Contact us : +6281287000949</h4>
          </div>
          <div className="privasi"></div>
        </div>
      </div>
      <div className="container-bawah">© Asya Jakarta</div>
    </div>
  );
};

export default footer;
