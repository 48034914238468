import Toba from '../../Media/Logo Asya/Toba.png'
import Maninjau from '../../Media/Logo Asya/Maninjau.png'
import Kelimutu from '../../Media/Logo Asya/Kelimutu.png'
import Matana from '../../Media/Logo Asya/Matana.png'
import Sentarum from '../../Media/Logo Asya/Sentarum.png'

const clusterimages =[
Toba,Maninjau,Kelimutu,Matana,Sentarum
]

export default clusterimages;