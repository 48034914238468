import React from "react";
import "./about.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import logosumban from "../../Media/asya-logo-biru.png";

const aboutsumban = () => {
  const aboutwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://eonna-bsdcity.com/%20()&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div id="about" className="about-sumban">
      <div className="containerlogo">
        <div className="logosumban">
          <img className="gambarsumban" src={logosumban} alt="logo sumban" />
        </div>
      </div>
      <div className="containerdesk">
        <div className="judul1">
          <h1>Beautiful Life, Beautiful Living</h1>
        </div>
        <div className="desk">
          Asya adalah sebuah kawasan perumahan eksklusif yang terletak di
          Jakarta Garden City, Jakarta Timur. Asya menawarkan konsep "lakeside
          premium township" yang memadukan keindahan alam dan fasilitas modern.
          Asya memiliki danau seluas 15 hektar dengan taman di sekitarnya,
          menciptakan suasana yang asri dan menyegarkan. Konsep "lakeside
          living" memberikan kesempatan untuk menikmati hidup yang lebih
          berkualitas dengan lingkungan yang asri dan nyaman. Dilengkapi dengan
          berbagai fasilitas seperti clubhouse, kolam renang, area bermain anak,
          jogging track, dan keamanan 24 jam.
        </div>
      </div>
    </div>
  );
};

export default aboutsumban;
