import Fasilitas1 from '../../../src/Media/Fasilitas Asya/fasilitas (1).webp'
import Fasilitas2 from '../../../src/Media/Fasilitas Asya/fasilitas (2).webp'
import Fasilitas3 from '../../../src/Media/Fasilitas Asya/fasilitas (3).webp'
import Fasilitas4 from '../../../src/Media/Fasilitas Asya/fasilitas (4).webp'
import Fasilitas5 from '../../../src/Media/Fasilitas Asya/fasilitas (5).webp'
import Fasilitas6 from '../../../src/Media/Fasilitas Asya/fasilitas (6).webp'
import Fasilitas7 from '../../../src/Media/Fasilitas Asya/fasilitas (7).webp'
import Fasilitas8 from '../../../src/Media/Fasilitas Asya/fasilitas (8).webp'

const Fasilitasimages =[
    Fasilitas1,Fasilitas2,Fasilitas3,Fasilitas4,Fasilitas5,Fasilitas6,Fasilitas7,Fasilitas8,
]

export default Fasilitasimages;