import React from "react";
import "./ruko.scss";
import Ruko1 from "./Produk1/ruko1";

const ruko = () => {
  return (
    <div id="ruko" className="ruko">
      <div>
        <div className="Judul"></div>
        <div className="ruko-card">
          <Ruko1 />
        </div>
      </div>
    </div>
  );
};

export default ruko;
