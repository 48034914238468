import Genova1 from '../../../Media/Ruko/Ruko (1).webp'
import Genova2 from '../../../Media/Ruko/Ruko (2).webp'
import Genova3 from '../../../Media/Ruko/Ruko (3).webp'
import Genova4 from '../../../Media/Ruko/Ruko (4).webp'
import Genova5 from '../../../Media/Ruko/Ruko (5).webp'
import Genova6 from '../../../Media/Ruko/Ruko (6).webp'
import Genova7 from '../../../Media/Ruko/Ruko (7).webp'
import Genova8 from '../../../Media/Ruko/Ruko (8).webp'

const Genovaimages =[
Genova1,Genova2,Genova3,Genova4,Genova5,Genova6,Genova7,Genova8
]

export default Genovaimages;